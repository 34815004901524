<template>
  <v-btn icon @click="download" color="green">
    <v-icon>mdi-cloud-download</v-icon>
  </v-btn>
</template>
<script>
import axios from "axios";

export default {
  props: ['url'],
  methods: {
    download() {
      axios.get(this.url, {
        headers:
            {
              'Content-Disposition': "attachment; filename=template.xlsx",
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
        responseType: 'arraybuffer',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let filename = 'exported-data'
        link.setAttribute('download', filename + '.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error)
      })
    },
  }
}
</script>