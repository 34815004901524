<template>
  <v-card>
    <v-card-text>
      <v-btn text color="primary" :to="{name: 'FormFillUpRequestsPending'}">Pending Requests</v-btn>
      <v-btn text color="primary" :to="{name: 'FormFillUpRequestsAccepted'}">Accepted Requests</v-btn>
      <v-btn text color="primary" :to="{name: 'FormFillUpRequestsComplete'}">Complete Requests</v-btn>
    </v-card-text>
    <v-card-title class="align-baseline">
      Form Fill Up Requests
      <v-switch
          v-model="pending"
          class="pa-3"
          label="Pending"
      ></v-switch>
      <v-switch
          v-model="confirmed"
          class="pa-3"
          label="Accepted"
      ></v-switch>
      <v-switch
          v-model="complete"
          class="pa-3"
          label="Complete"
      ></v-switch>
      <div style="max-width: 200px">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                label="Choose a date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <div style="max-width: 200px">
        <v-select
            v-model="unit"
            :items="customUnitList"
            item-text="name"
            item-value="id"
            label="Select unit"
            prepend-icon="mdi-book-open"
            hide-details
            clearable
        ></v-select>
      </div>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
      <export-data :url="exportUrl"/>
    </v-card-title>
    <v-card-subtitle v-if="selected.length">
      <v-row>
        <v-col>
          <v-toolbar
              dense
              dark
              rounded
              outlined
              max-width="200"
          >
            <v-btn icon small @click="bulkAccept">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn icon small @click="bulkDelete">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-data-table
        dense
        :headers="headers"
        :items="requests"
        :options.sync="options"
        :server-items-length="totalRequests"
        :footer-props="footerProps"
        :items-per-page="20"
        sort-desc
        :loading="loading"
        item-key="id"
        :search="search"
        class="elevation-1"
        show-select
        v-model="selected"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.user.name="{ item }">
        <router-link :to="{name: 'StudentInfo',params:{formFillUpRequestId: item.id}}">
          {{ item.user.name }}
        </router-link>
      </template>
      <template v-slot:item.phone="{ item }">
        <router-link :to="{name: 'StudentInfo',params:{formFillUpRequestId: item.id}}">
          {{ item.phone }}
        </router-link>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.updated_at | calendar }}
      </template>
      <template v-slot:item.unit.name="{ item }">
        {{ item.unit.name + ', ' + item.unit.varsity.name }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            color="green"
            @click="editItem(item)"
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
            color="red"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import ExportData from "@/components/admin/utilities/ExportData";

export default {
  components: {ExportData},
  data() {
    return {
      requests: [],
      selected: [],
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, 500]
      },
      date: '',
      units: [],
      unit: '',
      menu: false,
      search: '',
      page: 1,
      pageCount: 0,
      totalRequests: 0,
      options: {},
      loading: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: 'User ID', value: 'user.id', sortable: false},
        {text: 'Name', value: 'user.name', sortable: false},
        {text: 'Phone', value: 'phone'},
        {text: 'Unit', value: 'unit.name', sortable: false},
        {text: 'Amount', value: 'amount'},
        {text: 'Tran. ID', value: 'transaction_number'},
        {text: 'Confirmed', value: 'confirmed'},
        {text: 'Step', value: 'step'},
        {text: 'Payment Method', value: 'method'},
        {text: 'Time', value: 'created_at'},
        {text: 'Action', value: 'actions', sortable: false}
      ],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      selectedItemForDelete: '',
      free: false,
      confirmed: false,
      pending: false,
      complete: false,
      filter: '',
      exportUrl: '',
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    selectedIds() {
      return this.selected.map(a => a.id)
    },
    filterTerm() {
      if (this.complete) {
        return 'filter=complete'
      } else if (this.confirmed) {
        return 'filter=confirmed'
      } else if (this.pending) {
        return 'filter=pending'
      } else {
        return 'filter=all'
      }
    },
    sortTerm() {
      return this.options.sortBy[0] ? '&sort=' + this.options.sortBy[0] : ''
    },
    dateTerm() {
      return this.date ? '&date=' + this.date : ''
    },
    unitTerm() {
      return this.unit ? '&unit_id=' + this.unit : ''
    },
    descTerm() {
      return this.options.sortDesc[0] ? '&desc=' + this.options.sortDesc[0] : ''
    },
    customUnitList() {
      let units = []
      this.units.forEach((unit) => {
        units.push({name: unit.name + ', ' + unit.varsity.name, id: unit.id})
      })
      return units;
    }
  },

  watch: {
    options: {
      handler() {
        this.initialize()
      },
      deep: true,
    },
    filterTerm() {
      this.initialize()
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    search() {
      if (this.search.length) {
        this.searchPayment()
      } else {
        this.initialize()
      }
    },
    free() {
      if (this.free) {
        this.confirmed = false
        this.pending = false
      }
    },
    confirmed() {
      if (this.confirmed) {
        this.free = false
        this.pending = false
        this.complete = false
      }
    },
    pending() {
      if (this.pending) {
        this.confirmed = false
        this.free = false
        this.complete = false
      }
    },
    complete() {
      if (this.complete) {
        this.confirmed = false
        this.free = false
        this.pending = false
      }
    },
    date() {
      this.initialize()
    },
    unit() {
      this.initialize()
    }
  },
  created() {
    this.loadUnits()
  },
  methods: {
    loadUnits() {
      const url = 'admin/units?filter=all'
      this.exportUrl = 'admin/form-fill-up-export-request?filter=all'
      axios.get(url).then((response) => {
        this.units = response.data
      })
    },
    bulkAccept() {
      Swal.fire({
        title: 'Confirmation',
        text: "Do you want to accept these requests?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/form-fill-up-request/action/bulk'
          axios.post(url, {ids: this.selectedIds, type: 'bulk_accept'}).then(() => {
            Swal.fire(
                'Success!',
                'success'
            )
            this.selected = []
            this.initialize()
          })
        }
      })
    },
    bulkDelete() {
      Swal.fire({
        title: 'Confirmation',
        icon: 'warning',
        text: "Do you want to delete these requests?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/form-fill-up-request/action/bulk'
          axios.post(url, {ids: this.selectedIds, type: 'bulk_delete'}).then(() => {
            Swal.fire(
                'Deleted!',
                'success'
            )
            this.selected = []
            this.initialize()
          })
        }
      })
    },
    initialize() {
      this.loading = true
      let link = 'admin/form-fill-up-request?' + this.filterTerm + '&page=' + this.options.page + this.sortTerm + this.descTerm + this.unitTerm + this.dateTerm + '&per_page=' + this.options.itemsPerPage
      this.exportUrl = 'admin/form-fill-up-export-request?' + this.filterTerm + '&page=' + this.options.page + this.sortTerm + this.descTerm + this.unitTerm + this.dateTerm + '&per_page=' + this.options.itemsPerPage
      axios.get(link).then((response) => {
        this.requests = response.data.data
        this.totalRequests = response.data.total
        this.loading = false
      })
    },
    searchRequest(page = 1) {
      this.loading = true
      let link = 'admin/form-fill-up-request?filter=search&page=' + page + '&query=' + this.search
      axios.get(link).then((response) => {
        this.requests = response.data.data
        this.totalRequests = response.data.total
        this.loading = false
      })
    },
    editItem(item) {
      const url = 'admin/form-fill-up-request/' + item.id;
      let data = {
        confirmed: 1,
        step: 'accepted'
      };
      axios.put(url, data).then(() => {
        this.initialize();
      })
    },
    deleteItem(item) {
      this.editedIndex = this.requests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedItemForDelete = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const url = 'admin/form-fill-up-request/' + this.selectedItemForDelete.id;
      axios.delete(url).then(() => {
        this.requests.splice(this.editedIndex, 1);
        this.closeDelete();
      }).catch((error) => {
        console.log(error)
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.requests[this.editedIndex], this.editedItem)
      } else {
        this.requests.push(this.editedItem)
      }
      this.close()
    },
  },
  filters: {
    calendar(value) {
      return moment(value).format('D-MMM-Y hh:mm a')
    }
  }
}
</script>